import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";


class About extends Component{
    render(){
        let title = 'About',
        description = 'MSIM Consulting brings together cross-industry expertise, innovation and passion. We specialise in the discovery, planning and delivery of medium to large scale digital projects. We drive transformation and build businesses by bringing together the capabilities needed to help organisations grow and thrive in the digital age. ';
        return(
            <React.Fragment>
                <PageHelmet pageTitle='About' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'About'}   />
                {/* End Breadcrump Area */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div>
                                        <img className="w-100" src="/assets/images/about/about1.png" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p className="description">{description}</p>
                                        </div>
                                        <div className="row mt--30">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Our history</h3>
                                                    <p>The key to our success is the team that we’ve put together. Over the past years, our engineers, architects, developers, agile coaches, UX/UI designers, and cybersecurity experts have worked seamlessly across Europe and the United States helping our clients with their digital transformations.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Our mission</h3>
                                                    <p>We are here to develop your idea and help you fulfil your vision. We empower our clients to create change that matters—transformation, enabled by technology and sustained through capabilities. At the same time, we aim to create an environment for excellence to attract, develop and retain exceptional people.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <FooterTwo />

            </React.Fragment>
        )
    }
}
export default About