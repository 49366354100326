import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FiCheck } from "react-icons/fi";

class TabsOne extends Component{
    render(){
        let 
        tab1 = "Our history", 
        tab2 = "Our mission"
        const { tabStyle } = this.props;

        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                    </TabList>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>The key to our success is the team that we’ve put together. Over the past years, our  engineers, architects, developers, agile coaches, UX/UI designers, and cybersecurity experts have worked seamlessly across Europe and the United States helping our clients with their digital transformations. </p>
                                            <OurValues />
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <p>We are here to develop your idea and help you fulfil your vision. We empower our clients to create change that matters—transformation, enabled by technology and sustained through capabilities. At the same time, we aim to create an environment for excellence to attract, develop and retain exceptional people.</p>
                                           <OurValues />
                                        </div>
                                    </TabPanel>
                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}

class OurValues extends React.Component {
    render() {
        var namesItemOne = [
            'Create client value ',
            'Act with integrity ',
            'Respect individuals ',
            'Work together',
            'Innovate',
        ];
        return (
            <div className="mt--30">
            <h4>Our values</h4>
            <ul className="list-style--1">
                {namesItemOne.map((name, index) => {
                    return <li key={ index }><FiCheck /> {name}</li>;
                })}
            </ul>
        </div>
        )
    }
}


export default TabsOne;