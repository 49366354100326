import React, { Component , Fragment } from "react";
import Slider from "react-slick";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import { slideSlick } from "../page-demo/script";
import ServiceList from "../elements/service/ServiceList";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import TabOne from "../elements/tab/TabOne";
import Helmet from "../component/common/Helmet";
import { Link } from 'react-router-dom'

const SlideList = [
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--1',
        category: '',
        title: 'MSIM Consulting',
        description: `Unparalleled and innovative cloud based solutions, tailored for your business `,
        buttonText: 'Contact Us',
        buttonLink: '/contact'
    },
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--1',
        category: '',
        title: 'Digital Transformation',
        description: 'Leveraging multi-cloud platforms to help our clients add value to their business',
        buttonText: 'Contact Us',
        buttonLink: '/contact'
    },
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--1',
        category: '',
        title: 'Design',
        description: 'Increase your conversion and retention rates with the help of our UI/UX design teams',
        buttonText: 'Contact Us',
        buttonLink: '/contact'
    }, //Working software is not the only thing that adds value to a business. 
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--1',
        category: '',
        title: 'Voice Technologies',
        description: 'Helping companies getting closer to their clients and ahead of the competition ',
        buttonText: 'Contact Us',
        buttonLink: '/contact'
    } //regardless of their size and budget
]

class DigitalAgency extends Component{
    render(){
        let title = 'About',
        description = 'MSIM Consulting brings together cross-industry expertise, innovation and passion. We specialise in the discovery, planning and delivery of medium to large scale digital projects. We drive transformation and build businesses by bringing together the capabilities needed to help organisations grow and thrive in the digital age. ';
        return(
            <Fragment> 
                
                <Helmet pageTitle="Home" />

                {/* Start Header Area  */}
                <Header logo="light" color="color-white"/>
                {/* End Header Area  */}
                
                {/* Start Slider Area   */}
                <div className="slider-wrapper color-white">
                    <div className="slider-activation slider-digital-agency">
                        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                            {SlideList.map((value , index) => (
                                <div className={`slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index} data-black-overlay="2">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h1 className="title">{value.title}</h1> : ''}
                                                    {value.description ? <p className="description">{value.description}</p> : ''}
                                                    {/* {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={"/contact"}>{value.buttonText}</a></div> : ''} */}
                                                    {value.buttonText ? <div className="slide-btn"><Link to="/contact" className="rn-button-style--2 btn-primary-color" >{value.buttonText}</Link></div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
                {/* End Slider Area   */}


                {/* Start About Area */}
                <div className="about-area ptb--120  bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35">
                                <div className="col-lg-5">
                                    <div>
                                        <img className="w-100" src="/assets/images/about/about1.png" alt="About"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p className="description">{description}</p>
                                        </div>
                                        <div className="row mt--30">
                                            <TabOne tabStyle="tab-style--1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

                {/* Start Service Area  */}
                <div className="service-area pt--120 pb--50 bg_image bg_image--3">
                   <div className="container">
                       <div className="row">
                           <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30">
                                    <h2 className="title">Our Service</h2>
                                    <p>MSIM Consulting specialises in delivering cloud powered solutions and our services are geared towards helping businesses throughout their digital transformation journey.</p>
                                </div>
                           </div>
                       </div>
                        <ServiceList item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-center" />
                   </div>
                </div>  
                {/* End Service Area  */} 
                {/* Start call To Action  */}
                <CallAction />
                {/* End call To Action  */}

                {/* Start Footer Style  */}
                <FooterTwo />
                {/* End Footer Style  */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}

export default DigitalAgency;