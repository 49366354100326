// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';
import { Link, HashRouter } from 'react-router-dom'
// Common Layout
// import Layout from "./component/common/App";


import DigitalAgency from './home/DigitalAgency';
import ServiceDetails from "./elements/ServiceDetails";
import About from "./elements/About";
import Contact from "./elements/Contact";
import error404 from "./elements/error404";

import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';


class Root extends Component{
    render(){
        return(
            <HashRouter basename="/">
                <Switch>
                    <Route exact path={`${process.env.PUBLIC_URL}/`} component={DigitalAgency}/>

                    {/* Element Layot */}
                    <Route exact path={`${process.env.PUBLIC_URL}/home`} component={ServiceDetails}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/service`} component={ServiceDetails}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/service-details`} component={ServiceDetails}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About}/>

                    <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                    <Route component={error404}/>

                </Switch>
            </HashRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();