import React ,{ Component }from "react";
import {FiSettings, FiFigma , FiMonitor, FiUploadCloud, FiRadio, FiSmartphone } from "react-icons/fi";
import { HashLink as Link} from 'react-router-hash-link';

const ServiceList = [
    {
        icon: <FiFigma />,
        title: 'UI Design',
        description: 'Creating user interfaces to match the expectations of the customers.',
        link: "service#ui-design"
    },
    {
        icon: <FiMonitor />,
        title: 'Website Development',
        description: 'Building cloud native websites and applications using latest technologies.',
        link: "service#website-development"
    },
    { 
        icon: <FiSettings />,
        title: 'Product Modernisation',
        description: 'Modernising your current workload using the latest technologies.',
        link: "service#product-modernisation"
    },
    { 
        icon: <FiSmartphone />,
        title: 'Mobile App Development',
        description: 'Building React Native mobile applications available for IOS and Android.',
        link: "service#mobile-applications"
    },
    {
        icon: <FiRadio />,
        title: 'Voice Technologies',
        description: 'Creating customised Alexa Skills that fit your business need.',
        link: "service#voice-technologies"
    },
    {
        icon: <FiUploadCloud />,
        title: 'Migrations to the cloud',
        description: 'Moving existing applications from on-prem to the AWS cloud.',
        link: "service#cloud-migration"
    },
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <Link to={val.link}>
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
