import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";

const services = [
    {
        title: "Title",
        image: "/assets/images/service/UIDesign.png",
        content: "Paragraph1",
        element1: "Element1",
        element2: "Element2",
        element3: "Element3",
        element4: "Element4",


    }
]

class ServiceDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Service Details' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1"  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Services</h2>
                                    <p>Our services include but are not limited to the following: </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        <div id="ui-design">
                                        <ServiceLeft image="/assets/images/service/UIDesign.png" title="UI Design" content="Nowadays customers aren’t interested only in functional software , but also in a seamless user experience. Our UI/UX design teams deliver user interfaces that increase conversion, reduce cost of maintenance and improve client retention rates."
                                        element1="Wireframes" element2="Mockups" element3="User research" element4="Increase retention and conversion rates"/>
                                        </div>
                                        <div id="website-development">
                                        <ServiceRight image="/assets/images/service/WebsiteDevelopment.png" title="Website development" content="A well built website will create seamless user journeys and significantly increase the conversion rate. From designing wireframes to prototyping and delivering the final product, MSIM Consulting strives for excellence. "
                                        element1="Cloud based solutions" element2="Scaling on demand" element3="Accessibility" element4="Increasing conversion and retention" />
                                        </div>
                                        <div id="product-modernisation">
                                        <ServiceLeft image="/assets/images/service/ProductModernisation.png" title="Product Modernisation"content="One of our guiding principles here as MSIM Consulting is to always innovate. To keep up with the latest advancements in the digital age, applications had to evolve from monolithic to micro-services architecture. As such, the solutions that we deliver have increased resilience, improved scalability and a shorter time to market."
                                        element1="DevOps" element2="Agile" element3="Multi-cloud/on-premises solutions " element4="Improved ROI with reduced TCO" />
                                        </div>
                                        <div id="mobile-applications">
                                        <ServiceRight image="/assets/images/service/MobileDevelopment.png" title="Mobile Applications" content="Nowadays the number of mobile users has surpassed that of desktop users. To help our clients stay ahead of the competition, MSIM Consulting leverages cloud resources to build react-native applications that can be deployed on iOS and Android devices."
                                        element1="React Native Applications" element2="IOS Native Applications" element3="Android Native Applications" element4="Hybrid Applications"/>
                                        </div>
                                        <div id="voice-technologies">
                                        <ServiceLeft image="/assets/images/service/VoiceTech.png" title="Voice Technologies" content="Voice technology is becoming more and more accessible and companies of all sizes are looking to include it in their portfolio. Wether your business is looking for Alexa enterprise to ease organising agendas and events or you’re interested in having your own tailored Alexa skill, MSIM Consulting is here to help and guide you."
                                        element1="Alexa Skills with ISP" element2="Alexa integration with 3rd party software" element3="Natural language processing (NLP) " element4="Using APL to improve accessibility " />
                                        </div>
                                        <div id="cloud-migration">
                                        <ServiceRight image="/assets/images/service/CloudMigration.png" title="Cloud Migration" content="Having a cloud based infrastructure will make your products more accessible, enabling you to go global in minutes. At the same time, changes in demand won’t be a problem anymore as with cloud computing you can access as much or as little capacity as you need, and scale up and down as required with only a few minutes’ notice."
                                        element1="Scalability" element2="Accessibility" element3="Automation" element4="Resilience" />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                <FooterTwo />
            </React.Fragment>
        )
    }
}
export default ServiceDetails;

class ServiceLeft extends React.Component {
    render() {
        return (
            <div className="row sercice-details-content pb--80 align-items-center">
            <div className="col-lg-6 col-12">
                <div className="thumb">
                    <img className="w-100" src={this.props.image} alt="Service Images"/>
                </div>
            </div>
            <div className="col-lg-6 col-12">
                <div className="details mt_md--30 mt_sm--30">
                <h4 className="title">{this.props.title}</h4>
                    <p>{this.props.content}</p>
                    <br />
                    <ul className="liststyle">
                        <li>{this.props.element1}</li>
                        <li>{this.props.element2}</li>
                        <li>{this.props.element3}</li>
                        <li>{this.props.element4}</li>
                    </ul>
                </div>
            </div>
        </div>
        )
    }
}

class ServiceRight extends React.Component {
    render() {
        return (
            <div className="row sercice-details-content pb--80 align-items-center">
            <div className="col-lg-6 col-12 order-2 order-lg-1">
                <div className="details mt_md--30 mt_sm--30">
                <h4 className="title">{this.props.title}</h4>
                    <p>{this.props.content}</p>
                    <br />
                    <ul className="liststyle">
                        <li>{this.props.element1}</li>
                        <li>{this.props.element2}</li>
                        <li>{this.props.element3}</li>
                        <li>{this.props.element4}</li>
                    </ul>
                </div>
            </div>
            <div className="col-lg-6 col-12 order-1 order-lg-2">
                <div className="thumb position-relative">
                    <img className="w-100" src={this.props.image} alt="Service Images"/>
                </div>
            </div>
        </div>
        )
    }
}