import React, { Component } from "react";
import { validate } from 'validate.js';

const constraints = {
    emailAddress: {
      presence: {
        allowEmpty: false,
        message: "^Please enter an email address"
      },
      email: {
        message: "^Please enter a valid email address"
      }
    },
  };


class ContactTwo extends Component{
    constructor(props){
        super(props);
        this.state = {
            showError: false,
            showSuccessfulSubmition: false,
            rnName: '',
            rnSubject: '',
            rnMessage: '',
            data: {
                emailAddress: '',}
        };
        this.handleClick=this.handleClick.bind(this);
    }

    handleClick() {
        console.log("Button has been clicked")
        const validationResult = validate(this.state.data, constraints);
        console.log(validationResult)
        const apiEndpoint = "https://1ks61b9rz8.execute-api.us-east-1.amazonaws.com/beta/contact-us";

        if (validationResult !== undefined) {
            console.log("The request has not been sent")
            this.setState({showSuccessfulSubmition: false})
            this.setState({showError: true});
        }
        if (validationResult === undefined) {
            console.log("The request has been sent")
            this.setState({showSuccessfulSubmition: true})
            this.setState({showError: false});

            // create a new XMLHttpRequest
            var requestData = JSON.stringify({
                'name' : this.state.rnName,
                'email' : this.state.data.emailAddress,
                'subject' : this.state.rnSubject,
                'message' : this.state.rnMessage
            });
            var xhr = new XMLHttpRequest();
            xhr.open("POST", apiEndpoint, true);
            xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
            xhr.onreadystatechange = function () 
            {
                if (xhr.readyState === 4 && xhr.status === 200) 
                {
                    var json = JSON.parse(xhr.responseText);
                }
            };
            xhr.send(requestData)
        }


    }

    render() {
        const errorBox = (
            <div style={{display: (this.state.showError ? 'block' : 'none' ), color:"red"}}> 
                Please enter a valid email address
            </div> )
        const successfulSubmit = (
            <h4 style={{display: (this.state.showSuccessfulSubmition ? 'block' : 'none' ), color:"#323232", textAlign:'center'}}> 
                Thank you for your query. We aim to get back to you in the next 48 hours.
            </h4> )

        return(
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <h2 className="title">Contact Us.</h2>
                                <p className="description"></p>
                            </div>
                            <div className="form-wrapper">
                                    <label htmlFor="item01">
                                        <input
                                            type="text"
                                            name="name"
                                            id="item01"
                                            value={this.state.rnName}
                                            onChange={(e)=>{this.setState({rnName: e.target.value});}}
                                            placeholder="Your Name "
                                        />
                                    </label>
                                    {errorBox}
                                    <label htmlFor="item02">
                                        <input
                                            type="text"
                                            name="email"
                                            id="item02"
                                            value={this.state.data.emailAddress}
                                            onChange={(e)=>{this.setState({data: {emailAddress: e.target.value}});}}
                                            placeholder="Your email *"
                                        />
                                    </label>

                                    <label htmlFor="item03">
                                        <input
                                            type="text"
                                            name="subject"
                                            id="item03"
                                            value={this.state.rnSubject}
                                            onChange={(e)=>{this.setState({rnSubject: e.target.value});}}
                                            placeholder="Write a Subject"
                                        />
                                    </label>
                                    <label htmlFor="item04">
                                        <textarea
                                            type="text"
                                            id="item04"
                                            name="message"
                                            value={this.state.rnMessage}
                                            onChange={(e)=>{this.setState({rnMessage: e.target.value});}}
                                            placeholder="Your Message"
                                        />
                                    </label>
                                    <button onClick={this.handleClick} className="rn-button-style--2 btn-solid" id="mc-embedded-subscribe">Submit</button>
                                    <br />  <br />
                                     {successfulSubmit}
                                
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="mb_md--30 mb_sm--30">
                                <img src="/assets/images/contact/contact-2.png" alt="Contact"/>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactTwo;
